// Chinese phrases

export default {
  com_ui_examples: '例子',
  com_ui_new_chat: '新对话',
  com_ui_example_quantum_computing: '如何给7岁小孩讲解量子计算？',
  com_ui_example_10_year_old_b_day: '如何举办生日宴才能耳目一新？',
  com_ui_example_http_in_js: '如何在Python中实现HTTP请求？',
  com_ui_capabilities: '特点',
  com_ui_capability_remember: '历史对话记录',
  com_ui_capability_correction: '实时提供反馈',
  com_ui_capability_decline_requests: '阻止非法请求',
  com_ui_limitations: '限制',
  com_ui_limitation_incorrect_info: '可能会不时出现错误信息',
  com_ui_limitation_harmful_biased: '可能会提供有害指示或者偏见',
  com_ui_limitation_limited_2021: '训练基于2021年以前信息',
  com_ui_input: '输入',
  com_ui_close: '关闭',
  com_ui_model: '模型',
  com_ui_select_model: '模型选择',
  com_ui_use_prompt: '使用提示词',
  com_ui_prev: '上一页',
  com_ui_next: '下一页',
  com_ui_prompt_templates: '对话模板',
  com_ui_hide_prompt_templates: '隐藏对话模板',
  com_ui_showing: '显示',
  com_ui_of: '/',
  com_ui_entries: '项',
  com_auth_error_login: '无法登录，请确认提供的账户密码正确，并重新尝试。',
  com_auth_no_account: '新用户注册',
  com_auth_sign_up: '注册',
  com_auth_sign_in: '登录',
  com_auth_google_login: '谷歌登录',
  com_auth_facebook_login: 'Facebook登录',
  com_auth_github_login: 'Github登录',
  com_auth_discord_login: 'Discord登录',
  com_auth_email: '电子邮箱',
  com_auth_email_required: '邮箱为必填项',
  com_auth_email_min_length: '邮箱地址至少6个字符',
  com_auth_email_max_length: '邮箱地址最多120个字符',
  com_auth_email_pattern: '请输入正确的电子邮箱格式',
  com_auth_email_address: '电子邮箱地址',
  com_auth_password: '密码',
  com_auth_password_required: '密码为必填项',
  com_auth_password_min_length: '密码至少8个字符',
  com_auth_password_max_length: '密码最多128个字符',
  com_auth_password_forgot: '忘记密码?',
  com_auth_password_confirm: '确认密码',
  com_auth_password_not_match: '密码不一致',
  com_auth_continue: '继续',
  com_auth_create_account: '创建账号',
  com_auth_error_create: '注册账户过程中出现错误，请重试。',
  com_auth_reset_password_link_sent: '重置密码链接已发送至邮箱',
  com_auth_reset_password_email_sent: '重置密码邮件已发送至邮箱',
  com_auth_full_name: '姓名',
  com_auth_name_required: '姓名为必填项',
  com_auth_name_min_length: '姓名至少3个字符',
  com_auth_name_max_length: '姓名最多80个字符',
  com_auth_username: '用户名（可选）',
  com_auth_username_required: '用户名为必填项',
  com_auth_username_min_length: '用户名至少3个字符',
  com_auth_username_max_length: '用户名最多20个字符',
  com_auth_already_have_account: '已有账号',
  com_auth_login: '登录',
  com_auth_reset_password: '重置密码',
  com_auth_click: '点击',
  com_auth_here: '这里',
  com_auth_to_reset_your_password: '重置密码.',
  com_auth_error_reset_password: '重置密码出现错误，未找到对应的邮箱地址，请重新输入。',
  com_auth_reset_password_success: '密码重置成功',
  com_auth_login_with_new_password: '现在你可以使用你的新密码登录.',
  com_auth_error_invalid_reset_token: '重置密码的密钥已失效。',
  com_auth_click_here: '点击这里',
  com_auth_to_try_again: '再试一次.',
  com_auth_submit_registration: '注册提交',
  com_auth_welcome_back: '欢迎',
  com_endpoint_bing_enable_sydney: '启用 Sydney',
  com_endpoint_bing_to_enable_sydney: '启用 Sydney',
  com_endpoint_bing_jailbreak: '破解',
  com_endpoint_bing_context_placeholder:
    '必应可以使用多达7000个词元作为“上下文（context）”，参照这些内容进行对话。其具体限制并不清楚，但可能会在超过7000个词元时出现错误',
  com_endpoint_bing_system_message_placeholder:
    '警告：滥用此功能可能导致你被禁止使用必应！点击“系统消息”查看完整的使用指南，如果你忽略了默认消息，那么将会使用被视为安全的“Sydney”预设。',
  com_endpoint_system_message: '系统消息',
  com_endpoint_default_blank: '初始值: 空',
  com_endpoint_default_false: '初始值: false',
  com_endpoint_default_creative: '初始值: creative',
  com_endpoint_default_empty: '初始值: empty',
  com_endpoint_default_with_num: '初始值: {0}',
  com_endpoint_context: '上下文',
  com_endpoint_tone_style: '语气',
  com_endpoint_token_count: '词元数',
  com_endpoint_output: '输出',
  com_endpoint_google_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和Top P。',
  com_endpoint_google_topp:
    'Top-p changes how the model selects tokens for output. Tokens are selected from most K (see topK parameter) probable to least until the sum of their probabilities equals the top-p value.',
  com_endpoint_google_topk:
    'Top-k changes how the model selects tokens for output. A top-k of 1 means the selected token is the most probable among all tokens in the model\'s vocabulary (also called greedy decoding), while a top-k of 3 means that the next token is selected from among the 3 most probable tokens (using temperature).',
  com_endpoint_google_maxoutputtokens:
    ' 	响应生成中可以使用的最大令牌数。指定较低的值会得到更短的响应，而指定较高的值则会得到更长的响应。',
  com_endpoint_google_custom_name_placeholder: '为PaLM2设置一个名称',
  com_endpoint_google_prompt_prefix_placeholder: '自定义指令和上下文，默认为空。',
  com_endpoint_custom_name: '自定义名称',
  com_endpoint_prompt_prefix: '对话前缀',
  com_endpoint_temperature: '温度',
  com_endpoint_default: '初始值',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '最大输出词元数',
  com_endpoint_openai_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和Top P。',
  com_endpoint_openai_max: '最大生成词元数。输入词元长度由模型的上下文长度决定。',
  com_endpoint_openai_topp:
    '相较于温度的另一个取样方法，称为核采样，模型选取输出词元中大于P值（概率密度在整个概率分布中的比例）的结果。比如 top_p=0.1 表示只有概率占比为前10%的词元才会被考虑作为输出。建议不要同时改变此值和温度。',
  com_endpoint_openai_freq:
    '值介于-2.0到2.0之间。正值表示根据已有词元的频率惩罚重复词元结果, 从而减少模型输出重复词。',
  com_endpoint_openai_pres:
    '值介于-2.0到2.0之间。正值表示根据出现词元惩罚重复词元结果, 从而增加模型提出新主题的可能性。',
  com_endpoint_openai_custom_name_placeholder: '为ChatGPT设置一个名称',
  com_endpoint_openai_prompt_prefix_placeholder: '可以在系统消息中设置自定义指令，默认为空',
  com_endpoint_frequency_penalty: '频度惩罚',
  com_endpoint_presence_penalty: '出现惩罚',
  com_endpoint_plug_use_functions: '使用函数',
  com_endpoint_plug_skip_completion: '跳过补全',
  com_endpoint_disabled_with_tools: '系统禁用',
  com_endpoint_disabled_with_tools_placeholder: '系统禁用',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    '可以在系统消息中设置自定义指令，默认为空',
  com_endpoint_set_custom_name: '设置一个自定义名，以方便寻找预设',
  com_endpoint_preset_name: '预设名',
  com_endpoint: '端口',
  com_endpoint_hide: '隐藏',
  com_endpoint_show: '显示',
  com_endpoint_examples: ' 例子',
  com_endpoint_completion: '补全',
  com_endpoint_agent: '代理',
  com_endpoint_show_what_settings: '显示{0}的设置',
  com_endpoint_save: '保存',
  com_endpoint_export: '导出',
  com_endpoint_save_as_preset: '保存为预设',
  com_endpoint_not_implemented: '未实现功能',
  com_endpoint_edit_preset: '预设编辑',
  com_endpoint_view_options: '查看选项',
  com_endpoint_my_preset: '我的预设',
  com_endpoint_agent_model: '代理模型 (推荐: GPT-3.5)',
  com_endpoint_completion_model: '补全模型 (推荐: GPT-4)',
  com_endpoint_func_hover: '将插件当做OpenAI函数使用',
  com_endpoint_skip_hover: '跳过补全步骤， 用于检查最终答案和生成步骤',
  com_nav_export_filename: '文件名',
  com_nav_export_filename_placeholder: '设置文件名',
  com_nav_export_type: '类型',
  com_nav_export_include_endpoint_options: '包含配置信息',
  com_nav_enabled: '打开',
  com_nav_not_supported: '未支持',
  com_nav_export_all_message_branches: '导出所有对话',
  com_nav_export_recursive_or_sequential: '递归或顺序？',
  com_nav_export_recursive: '递归',
  com_nav_export_conversation: '导出对话',
  com_nav_theme: '主题',
  com_nav_theme_system: '系统',
  com_nav_theme_dark: '暗',
  com_nav_theme_light: '亮',
  com_nav_clear: '清空',
  com_nav_clear_all_chats: '清空所有对话',
  com_nav_confirm_clear: '确认清空',
  com_nav_close_sidebar: '关闭侧边栏',
  com_nav_open_sidebar: '打开侧边栏',
  com_nav_log_out: '登出',
  com_nav_user: '默认用户',
  com_nav_clear_conversation: '清空对话',
  com_nav_clear_conversation_confirm_message: '请确认是否清空所有对话？此操作无法撤回。',
  com_nav_help_faq: '帮助',
  com_nav_settings: '设置',
  com_nav_search_placeholder: '文本搜索',
  com_nav_setting_general: '通用',
};
